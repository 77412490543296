import { configureStore } from '@reduxjs/toolkit';
import { api } from './api';
import { setupListeners } from '@reduxjs/toolkit/query';
import * as Sentry from '@sentry/react';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
    },
    middleware(getDefaultMiddleware) {
        return getDefaultMiddleware().concat(api.middleware);
    },
    enhancers(getDefaultEnhancers) {
        return getDefaultEnhancers().concat(sentryReduxEnhancer);
    },
    devTools: true,
});

setupListeners(store.dispatch);

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
